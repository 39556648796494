import { Apollo } from 'apollo-angular';
import { of } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { ROADREWARD_URL } from '@prcins/constants';
import { HttpClient } from '@angular/common/http';
import { GaragingQuery, PolicyDriversQuery, PolicyDiscountsQuery, PolicyPerksQuery, PolicyPremiumDetailsQuery, PropertyInfoQuery, CoverageDetailsQuery, CoverageDetailsHomeQuery, PolicyCrossSellPromotionsQuery } from "@prcins/utils";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
export class PolicyProviderService {
    constructor(http, apollo) {
        this.http = http;
        this.apollo = apollo;
    }
    getCrossSellPromotions(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyCrossSellPromotionsQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data));
    }
    getDriverDetails(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyDriversQuery,
            fetchPolicy: 'network-only',
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data.policyDetails));
    }
    getVehicleDetails(policyNumber, guid) {
        return this.apollo
            .watchQuery({
            query: GaragingQuery,
            variables: {
                policyNumber,
                guid
            },
            fetchPolicy: "no-cache"
        })
            .valueChanges.pipe(map(({ data }) => data.policyDetailsV2));
    }
    getPerksDetails(policyNumber) {
        return this.apollo.watchQuery({
            query: PolicyPerksQuery,
            variables: {
                policyNumber,
            },
        }).valueChanges.pipe(map(({ data }) => data.policyPerks));
    }
    getDiscountDetails(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyDiscountsQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data.policyDetails.discounts));
    }
    getPolicyPremiumDetails(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyPremiumDetailsQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data.policyDetails.premium));
    }
    getPropertyInfo(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PropertyInfoQuery,
            variables: {
                policyNumber
            },
            fetchPolicy: "no-cache"
        })
            .valueChanges.pipe(map(({ data }) => data));
    }
    getCoverageDetails(policyNumber) {
        return this.apollo
            .watchQuery({
            query: CoverageDetailsQuery,
            variables: {
                policyNumber
            },
            fetchPolicy: "no-cache"
        })
            .valueChanges.pipe(map(({ data }) => data));
    }
    getHomeCoverageDetails(policyNumber) {
        return this.apollo
            .watchQuery({
            query: CoverageDetailsHomeQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data));
    }
    addUpdateTextAlertDetails(payload) {
    }
    getRRDriversDetails(policyNumber) {
        return this.http.get(ROADREWARD_URL.DriverDetails + '/' + policyNumber).pipe(map((response) => {
            return { errorCode: response.errorCode, data: response.data };
        }), catchError(error => {
            return of({ errorCode: "RR-FAILURE", data: [] });
        }));
    }
}
PolicyProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PolicyProviderService_Factory() { return new PolicyProviderService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: PolicyProviderService, providedIn: "root" });
