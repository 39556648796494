import { HttpClient } from '@angular/common/http';
import { BillingDetailsQuery, PolicyInfoQuery, PolicySearchQuery, PolicyUsernameQuery, BalanceDetailsQuery, MobileRegistrationQuery, PolicyCrossSellPromotionsQuery } from '@prcins/utils';
import { Apollo } from 'apollo-angular';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { POLICY_URL, ROADREWARD_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "@angular/common/http";
export class PolicyProviderService {
    constructor(apollo, http) {
        this.apollo = apollo;
        this.http = http;
    }
    getPolicyInquiry(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicySearchQuery,
            variables: {
                policyNumber
            } //,
            //fetchPolicy: 'no-cache'
        })
            .valueChanges.pipe(map(({ data }) => data));
    }
    getCrossSellPromotions(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyCrossSellPromotionsQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data));
    }
    billingQuery(policyNumber) {
        return this.apollo.watchQuery({
            query: BillingDetailsQuery,
            variables: {
                policyNumber
            }
        }).valueChanges;
    }
    balanceDetails(policyNumber) {
        return this.apollo.watchQuery({
            query: BalanceDetailsQuery,
            variables: {
                policyNumber
            }
        }).valueChanges;
    }
    getBillingDetails(policyNumber) {
        return this.billingQuery(policyNumber).pipe(map(result => {
            const billingDetails = result.data.billingDetails;
            if (billingDetails) {
                //this.cachedBillingData[policyNumber] = true;
                billingDetails.lastPaymentAmnt = this.ifPresent(billingDetails.lastPaymentAmnt).replace(',', '');
                billingDetails.nextPaymentAmnt = this.ifPresent(billingDetails.nextPaymentAmnt).replace(',', '');
                billingDetails.pastDueAmnt = this.ifPresent(billingDetails.pastDueAmnt).replace(',', '');
                billingDetails.balance = this.ifPresent(billingDetails.balance).replace(',', '');
            }
            return billingDetails;
        }));
    }
    ifPresent(value) {
        if (!value) {
            return '';
        }
        return value;
    }
    policyGreetingDetails(policyNumber) {
        return this.apollo.watchQuery({
            query: PolicyInfoQuery,
            variables: {
                policyNumber: policyNumber
            }
        }).valueChanges;
    }
    getPolicyGreetingDetails(policyNumber) {
        return this.policyGreetingDetails(policyNumber).pipe(map(result => result.data.policySearchInfo));
    }
    getUserName(policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyUsernameQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => {
            return data.username || null;
        }));
    }
    getPayloadForBillingAlerts(policyNumber) {
        return this.apollo
            .watchQuery({
            query: MobileRegistrationQuery,
            variables: {
                policyNumber
            }
        })
            .valueChanges.pipe(map(({ data }) => data));
    }
    linkPolicy(payload, userId, userType) {
        return this.http.post(`${POLICY_URL.addPolicy}/${userId}/${userType}`, payload);
    }
    getRRDriversDetails(policyNumber) {
        return this.http.get(ROADREWARD_URL.DriverDetails + '/' + policyNumber).pipe(map((response) => {
            return { errorCode: response.errorCode, data: response.data };
        }), catchError(error => {
            return of({ errorCode: "RR-FAILURE", data: [] });
        }));
    }
}
PolicyProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PolicyProviderService_Factory() { return new PolicyProviderService(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.HttpClient)); }, token: PolicyProviderService, providedIn: "root" });
